import { Box, Button, colors } from "@mui/material";
import { useNavigate } from "react-router";

const NavBar = ({ isCouple = false, auth = false }) => {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      justifyContent="center"
      bgcolor={colors.grey[400]}
      columnGap={1}
    >
      <Button
        sx={{ my: 1 }}
        variant="contained"
        color="warning" 
        onClick={() => {
          navigate("/home");
        }}
      >
        home
      </Button>
      {auth && (
        <>
          {isCouple && (
            <Button
              sx={{ my: 1 }}
              variant="contained"
              color="secondary"
              onClick={() => {
                navigate("/jarvis");
              }}
            >
              Couple
            </Button>
          )}
          <Button
            sx={{ my: 1 }}
            variant="contained"
            color="info"
            onClick={() => {
              navigate("/general");
            }}
          >
            General
          </Button>
        </>
      )}
    </Box>
  );
};

export default NavBar;
