import { useState } from "react";
import { Button } from "./stories/Button";

function SheetTest(sheetCode: any, initialState: any) {
  const [isVisible, setIsVisible] = useState(initialState);

  return (
    <>
      <div>
        <Button
          primary
          square
          label={!isVisible ? "Show Sheets" : "hide Sheets"}
          backgroundColor={!isVisible ? "#76BA1B" : "#ED3419"}
          onClick={() => setIsVisible(!isVisible)}
        />
      </div>

      {isVisible && (
        <iframe
          src={`https://docs.google.com/spreadsheets/d/${sheetCode}/edit?usp=sharing`}
          frameBorder="0"
          width="90%"
          height="500"
        />
      )}
    </>
  );
}

export default SheetTest;
