import { Box, colors } from "@mui/material";
import Timeline from "./components/Timeline";

function GeneralDash() {
  return (
    <Box
      bgcolor={colors.blue[600]}
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
      minHeight={"100vh"}
    >
      <h2>Hi. You're a general User!</h2>
      <Timeline />
    </Box>
  );
}

export default GeneralDash;
