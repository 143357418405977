import { Box, Chip } from "@mui/material";
import { Check } from "@mui/icons-material";

const Timeline = () => {
  const TimelineItem = ({
    label,
    completed,
  }: {
    label: string;
    completed: boolean;
  }) => {
    return completed ? (
      <Chip icon={<Check />} color="success" label={label} />
    ) : (
      <Chip color="warning" label={label} />
    );
  };
  return (
    <Box display="flex" flexDirection="column" rowGap={1}>
      <TimelineItem label="Migrate to yarn" completed={true} />
      <TimelineItem label="Convert app to typescript" completed={true} />
      <TimelineItem label="Start file explorer" completed={false} />
      <TimelineItem label="Set up account management" completed={false} />
    </Box>
  );
};

export default Timeline;
