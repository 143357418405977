import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import "firebaseui/dist/firebaseui.css";
import { useEffect, useState } from "react";
import "firebase/compat/firestore";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router";

const firebaseConfig = {
  apiKey: "AIzaSyCHJtyYG-dI3fKEQNeXAu9755rBP0aVeoU",
  authDomain: "personal-site-mk-1.firebaseapp.com",
  projectId: "personal-site-mk-1",
  storageBucket: "personal-site-mk-1.appspot.com",
  messagingSenderId: "48289643119",
  appId: "1:48289643119:web:274a547d3bdca72225ab9a",
  measurementId: "G-4RENQN9KXS",
};

//const fireApp = firebase.initializeApp(firebaseConfig);
//let fireApp

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

function InitAuth() {
  const [authentication, setAuthState] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  setTimeout(() => {
    setIsVisible(authentication);
  }, 500); // Wait .5 seconds before setting isVisible to true
  var firebaseui = require("firebaseui");

  const navigate = useNavigate();

  useEffect(
    () =>
      getAuth().onAuthStateChanged((user) => {
        //console.log(user);
        if (user) {
          //the user has been logged in
          setAuthState(true);

          //loginText = "Authenticated!";
          console.log("Logged in");
        } else {
          //the user has been logged out
          setAuthState(false);

          //loginText = "Log-in";
          console.log("Logged out!!");
        }
      }),
    [authentication],
  );

  // The start method will wait until the DOM is loaded.
  //ui.start('#firebaseui-auth-container', uiConfig);

  var uiConfig = {
    callbacks: {
      uiShown: function () {
        //document.getElementById('loader').style.display = 'none';
      },
    },
    signInSuccessUrl: "jarvis",
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      //firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      //firebase.auth.GithubAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      //firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      //firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
    ],

    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: "<your-tos-url>",
    // Privacy policy url/callback.
    privacyPolicyUrl: function () {
      window.location.assign("<your-privacy-policy-url>");
    },
  };

  // Initialize the FirebaseUI Widget using Firebase.
  useEffect(() => {
    if (firebaseui.auth.AuthUI.getInstance()) {
      const ui = firebaseui.auth.AuthUI.getInstance();
      ui.start("#firebaseui-auth-container", uiConfig);
    } else {
      const ui = new firebaseui.auth.AuthUI(firebase.auth());
      ui.start("#firebaseui-auth-container", uiConfig);
    }
  }, [authentication]);
  //const ui = new firebaseui.auth.AuthUI(firebase.auth());
  //ui.start('#firebaseui-auth-container', uiConfig);
  //console.log(firebase.auth())

  return (
    <div>
      <h3 className="h31"> {authentication ? "Authenticated" : "Log-in"}</h3>

      <div hidden={authentication} id="firebaseui-auth-container"></div>

      {isVisible ? (
        <Box
          hidden={!authentication}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#054aac" }}
            onClick={() => {
              navigate("/jarvis");
            }}
          >
            Dashboard
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              //login.current.style.display = 'default';
              firebase.auth().signOut();
              console.log("Signed out");
              navigate("/");
            }}
          >
            Log-out
          </Button>
        </Box>
      ) : (
        <>
          <a className="h31">ACC</a>
        </>
      )}
    </div>
  );
}

export default InitAuth;
export { firebaseConfig };
