import SheetTest from "./SheetTest";
import { Box, colors } from "@mui/material";
import Timeline from "./components/Timeline";

const Jarvis = () => {
  return (
    <Box bgcolor={colors.deepPurple[300]}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <h2>This is our Couple page &#128150;</h2>
        <Timeline />
        <Box
          my={3}
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          rowGap={2}
        >
          {SheetTest("1q9VUnO0Huki771NXTEIvbPclJl-0N_OEJGiFb1L_GD8", false)}
        </Box>
      </Box>
    </Box>
  );
};

export default Jarvis;
