import "./App.css";
import logo from "./images/highResLogo.png";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Home from "./home";
import Jarvis from "./Jarvis";
import NavBar from "./components/navbar";
import GeneralDash from "./GeneralDash";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
//const whitelist = ['ayush@acchaudhary.com', 'cordi.campbell@gmail.com']; // Whitelist of allowed emails
const firebaseConfig = {
  apiKey: "AIzaSyCHJtyYG-dI3fKEQNeXAu9755rBP0aVeoU",
  authDomain: "personal-site-mk-1.firebaseapp.com",
  projectId: "personal-site-mk-1",
  storageBucket: "personal-site-mk-1.appspot.com",
  messagingSenderId: "48289643119",
  appId: "1:48289643119:web:274a547d3bdca72225ab9a",
  measurementId: "G-4RENQN9KXS",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

function App() {
  //const whitelist =getUsersArray();

  const [authentication, setAuthState] = useState(true);
  const [whitelist, setWhitelist] = useState<any[] | null>(null);
  const [whiteListed, setWhiteListedState] = useState(false);
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    async function getUsersArray() {
      if (whitelist === null && !isReady) {
        //trying to minimize db reads
        var users = db.collection("users");

        try {
          const usersSnapshot = await users.get();
          console.log("db read");
          const usersArray = usersSnapshot.docs.map(
            (doc) => doc.data()["email"],
          );
          setWhitelist(usersArray);
        } catch (error) {
          alert(
            "Database access error (see console)... reverting to local whitelist backup",
          );
          console.log("error");
          setWhitelist(["ayush@acchaudhary.com", "cordi.campbell@gmail.com"]);
        }

        //setWhitelist(['ayush@acchaudhary.com', 'cordi.campbell@gmail.com']);//only for dev purposes to prevent DB reads
        setIsReady(true);
      }
    }

    getUsersArray();
  }, []);

  const PrivateRoute = ({ children, alt }: { children: any; alt: any }) => {
    return authentication ? (
      whiteListed ? (
        children
      ) : (
        alt
      )
    ) : (
      <Navigate to="/Home" />
    );
  };

  useEffect(
    () =>
      getAuth().onAuthStateChanged((user) => {
        //console.log(user);

        if (user) {
          //the user has been logged in
          setAuthState(true);
          //console.log(whitelist);
          if (whitelist !== null && whitelist.includes(user.email)) {
            setWhiteListedState(true);
          } else {
            setWhiteListedState(false);
          }
        } else {
          //the user has been logged out
          setAuthState(false);
          setWhiteListedState(false);
        }
      }),
    [authentication, whitelist],
  );

  if (!isReady) {
    return (
      <div className="App-header">
        <img className="logo" src={logo} alt="ACC Logo" />
        <br />
        <div
          style={{
            border: "2px solid red",
            padding: "0px 50px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <a style={{ fontSize: 120, fontStyle: "thin" }}>Loading . . .</a>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Router>
        <Box>
          <NavBar auth={authentication} isCouple={whiteListed} />
          <Box minWidth="100%" minHeight="100vh" bgcolor={"#3d3e3f"}>
            <Routes>
              {/* This route is for home component 
              with exact path "/", in component props 
              we passes the imported component*/}
              <Route path="/home" element={<Home auth={authentication} />} />

              {/* This route is for home component 
              with exact path "/", in component props 
              we passes the imported component*/}
              <Route
                path="/resume"
                Component={() => {
                  window.location.href = "/files/resume.pdf";
                  return null;
                }}
              />

              {/* This route is for home component 
              with exact path "/", in component props 
              we passes the imported component*/}
              <Route
                path="/jarvis"
                element={
                  <PrivateRoute alt={<GeneralDash />}>
                    <Jarvis />
                  </PrivateRoute>
                }
              />

              <Route path="/general" element={<GeneralDash />} />

              {/* If any route mismatches the upper 
              route endpoints then, redirect triggers 
              and redirects app to home component with to="/" */}
              <Route path="/" element={<Navigate replace to="/home" />} />
            </Routes>
          </Box>
        </Box>
      </Router>
    );
  }
}

export default App;
