import logo from "./images/highResLogo.png";
import "./App.css";
import "./stories/button.css";

import InitAuth from "./initialAuth";

import { Button } from "./stories/Button";
import { useEffect } from "react";

function Home({ auth = false }) {
  //InitAuth();
  useEffect(() => {
    window.scrollTo({ top: auth ? 0 : 100 });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img className="logo" src={logo} alt="ACC Logo" />
        <br />
        <div
          style={{
            border: "2px solid red",
            padding: "0px 50px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h3> Are you looking for my resume? </h3>
            <h6>
              If you are interested in my resume, that can be downloaded without
              logging in!
            </h6>
          </div>
          <div style={{ verticalAlign: "middle", justifyContent: "center" }}>
            <Button
              primary={false}
              square
              backgroundColor="#ffffff"
              size="large"
              label="Resume"
              onClick={() => {
                window.location.href = "/files/resume.pdf";
              }}
            />
          </div>
        </div>

        <InitAuth />
      </header>
    </div>
  );
}

export default Home;
